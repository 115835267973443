// App.jsx
import { useState,useEffect } from 'react';
import './App.css';
import {  useRoutes } from 'react-router-dom';
import routes from './routes';

const App = () => {
  // 根据路由表生成对应的路由规则
  const ElementRouter = useRoutes(routes);

  const [scaleRadio,setScaleRadio] = useState(1)
  const options = { passive: true };
  useEffect(() => { 
    const resize = (event) => {
        let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
        let a = windowWidth /1920
        if(windowWidth<1000){
            window.scroll({ 
                top: 0, 
                left: 200,
                behavior: 'smooth'
             });
             setScaleRadio(0.88)  
        }else{
             setScaleRadio(a)
        }
    };
    resize()
    window.addEventListener("resize", resize,options);
  });
	return (
		  <div className='w-[1920px]'   style={{margin:'0 auto',zoom:scaleRadio,fontFamily:'NotoSansHans'}}>
        {ElementRouter} 
        		{/* 注册路由 */}
      </div>
	
		
	);
};

export default App;

