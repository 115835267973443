
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { message } from 'antd';
import { useState, useEffect } from 'react';
import intl from "../../locales";
import { useNavigate,useLocation } from 'react-router-dom'

export default function RulesDetails() {
    const navigate = useNavigate()
    const [rules, setRulesType] = useState([])
    const locale =localStorage.getItem('lang') || 'zh'
    const [activeRule, setRule] = useState(0)
    const [info,setInfo] = useState('')
    
    // const { id, type, activeRulesetup } = router.query //查看详情 如果type有值表示从入住通道查看协议不展示分类
    let pagestate = useLocation()
    const { id ,type} =pagestate.state
    console.log(id,type)
    useEffect(() => { 
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        // 协议详情
        async function fetchDataDetails() {
            // setRule(activeRulesetup) 
            const res = await fetch("https://fapi.aucfan-cn.com/site/site/getPolicyInfo", {
                method: 'POST',
                body:JSON.stringify({
                    id:id
                }),
                headers: { 
                    'Accept-Language': locale,
                    "Content-Type": 'json'
                }
            }); 
            const json = await res.json();
            if (json.code != 200) {
                message.error(json.message) 
            }
            setInfo(json.data) 
        }
        fetchDataDetails();
    }, [id])
    function menuClick(index) {
        setRule(index)
    }
    return (
        <>
            {/* <Head>
                <title>{info?info.title:''}</title>
            </Head> */}
            <Header muneActivepr={4} ></Header>
            <div className='flex flex-col items-center '>
                <div className="w-[100vw] h-[0px]  border-blue-200   mt-[88px] border-t"></div>
                <div className='w-[1300px] flex mt-[42px] items-start'>
                    {
                        type != 1 ?
                        <div className="w-[145px]  bg-[#FBFBFB]   ">
                            {
                                rules.map((ele, index) => {
                                    return (
                                        <div key={index} className={`cursor-pointer  text-[16px] font-medium  leading-[48px] h-[48px]  text-center ${index == activeRule ? 'text-[#2557A7] bg-[#F2F9FF] border-l-[3px] border-[#2557A7]' : 'text-[#333]'}`} onClick={() => menuClick(index)}>{ele.title}</div>
                                    )
                                })
                            }
                        </div> : ''
                    }
                    <div className="flex-1 ml-[85px] ">
                        <div className='flex  items-center '>
                                <div onClick={()=>{navigate(-1)}} className="w-[34px] h-[34px] flex justify-center items-center   rounded-full  bg-[#F5F5F5] cursor-pointer">
                                    <img src='/images/setp.png' width="20" height='20' alt="" className='w-[20px] h-[20px] object-cover'></img>
                                </div>
                            <div className="text-[#333] text-[16px] font-medium   leading-normal ml-[20px]">{intl.formatMessage({ id: '上一步' })}</div>
                        </div>
                        <div className="text-[#2557A7] text-[24px] font-medium   leading-7 mt-[30px]">{info.title}</div>
                        <div dangerouslySetInnerHTML={{ __html: info.text }} className="w-[1070px] text-[#333] text-[14px] font-normal   leading-[30px] mt-[44px]"></div>
                        <div className="text-[#333] text-[14px] font-normal   leading-tight mt-[58px] w-[100%] text-right">{intl.formatMessage({ id: '发布时间' })}：{info.create_time}</div>
                    </div> 
                </div>
                <div className="w-[1300px] mt-[80px] mb-[84px] flex items-center  text-[#2557A7] text-[16px] font-normal  leading-[100%]">
                    <div className="text-[#333]  font-normal ] "> {intl.formatMessage({ id: '首页' })}</div> 
                    <div className="px-[17px] text-[#333]  font-bold" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '政策规则' })}</div>
                </div>   
                <Footer />  
            </div>
        </>

    )
}