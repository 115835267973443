//路由表配置：src/routes/index.jsx
import { Navigate } from 'react-router-dom';

import Home from '../views/Home';
import Friend from '../views/Friend';
import CompanyProfile from '../views/AboutMe/CompanyProfile';
import Platform from '../views/AboutMe/Platform';
import Purchaser from '../views/EntryChannel/Purchaser';
import Supplier from '../views/EntryChannel/Supplier';
import HotNewsDetails from '../views/HotNews/HotNewsDetails';
import HotNewsHome from '../views/HotNews/HotNewsHome';
import OfficialConsultation from '../views/HotNews/OfficialConsultation';
import RulesLIst from '../views/PolicyRules/RulesLIst';
import RulesDetails from '../views/PolicyRules/RulesDetails';
// import NotFound from '../views/NotFound';
// import Chat from '../views/Chat';

const routes = [
  // Navigate 重定向
	{ path: '/',  element: <Navigate to='/home' /> },
	{ path: '/home', element: <Home /> },
    {path: '/friend',element: <Friend />},
    {path: '/aboutMe/companyProfile',element: <CompanyProfile />},
    {path: '/aboutMe/platform',element: <Platform />},
    {path: '/entryChannel/purchaser',element: <Purchaser />},
    {path: '/entryChannel/supplier',element: <Supplier />},
    {path: '/hotNews/hotNewsDetails',element: <HotNewsDetails />},
    {path: '/hotNews/hotNewsHome',element: <HotNewsHome />}, 
    {path: '/hotNews/officialConsultation',element: <OfficialConsultation />},  
    {path: '/policyRules/rulesLIst',element: <RulesLIst />}, 
    {path: '/policyRules/rulesLIstDetails',element: <RulesDetails />}, 
	// { 
	// 	path: '/friend', 
	// 	element: <Friend />,
	// 	children: [{ path: 'chat/:name', element: <Chat /> }],
	// },
	// { path: '/setting', element: <Setting /> },
	// { path: '*', element: <NotFound /> },
];

export default routes;
