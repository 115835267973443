
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Carousel, message } from 'antd';
import { useState, useEffect, useRef } from 'react'
import intl from "../../locales";
import { useNavigate } from 'react-router-dom'

export default function HotNewsHome() {
    const locale = localStorage.getItem('lang') || 'zh'
    const [swiperList, setSwiperList] = useState([])
    const [onmous, setMous] = useState(false) //鼠标是否移入
    const [type, setType] = useState([])
    const navigate = useNavigate()
    const carousel = useRef(null)
    const button_left = () => {
        carousel.current?.prev()
    }
    const button_right = () => {
        carousel.current?.next()
    }
    const gopage = (url, value) => {
        navigate(url, value)
    }
    useEffect(() => {
        // 轮播图

        async function fetchData() {
            const res = await fetch("https://fapi.aucfan-cn.com/site/site/getBanner", {
                method: 'POST',
                body: JSON.stringify({
                    type: 3
                }),
                headers: {
                    'Accept-Language': locale,
                    "Content-Type": 'application/json'
                },
            });
            const json = await res.json();
            console.log(json)
            if (json.code != 200) {
                message.error(json.message)
            }
            setSwiperList(json.data)
        }
        fetchData();

        // 热点资讯分类
        async function fetchDataType() {
            const resType = await fetch("https://fapi.aucfan-cn.com/site/site/getNewType", {
                method: 'POST',
                headers: {
                    'Accept-Language': locale,
                    "Content-Type": 'application/json'
                },
            });
            const jsonType = await resType.json();
            if (jsonType.code != 200) {
                message.error(jsonType.message)
            } else {
                let dataList = []
                const resList = await fetch("https://fapi.aucfan-cn.com/site/site/getNew", {
                    method: 'POST',
                    body: JSON.stringify({
                        page: 1,
                        limit: 3,
                        type_id: jsonType.data[0].id
                    }),
                    headers: {
                        'Accept-Language': locale,
                        "Content-Type": 'application/json'
                    },
                });
                const jsonList = await resList.json();
                if (jsonList.code != 200) {
                    message.error(jsonList.message)
                }
                dataList.push({
                    ...jsonType.data[0],
                    data: jsonList.data.data
                })
                console.log(123)
                const resList1 = await fetch("https://fapi.aucfan-cn.com/site/site/getNew", {
                    method: 'POST',
                    body: JSON.stringify({
                        page: 1,
                        limit: 3,
                        type_id: jsonType.data[1].id
                    }),
                    headers: {
                        'Accept-Language': locale,
                        "Content-Type": 'application/json'
                    },
                });
                const jsonList1 = await resList1.json();
                if (jsonList1.code != 200) {
                    message.error(jsonList1.message)
                }
                dataList.push({
                    ...jsonType.data[1],
                    data: jsonList1.data.data
                })
                console.log(456)
                const resList2 = await fetch("https://fapi.aucfan-cn.com/site/site/getNew", {
                    method: 'POST',
                    body: JSON.stringify({
                        page: 1,
                        limit: 3,
                        type_id: jsonType.data[2].id
                    }),
                    headers: {
                        'Accept-Language': locale,
                        "Content-Type": 'application/json'
                    },
                });
                const jsonList2 = await resList2.json();
                if (jsonList2.code != 200) {
                    message.error(jsonList2.message)
                }
                dataList.push({
                    ...jsonType.data[2],
                    data: jsonList2.data.data
                })
                setType(dataList)
                console.log(789)
                console.log(dataList, 'dataList')
            }
        }
        fetchDataType();
        window.scrollTo(0, 0)
    }, []);
    return (
        <div>
            {/* <Head>
                <title>{intl.formatMessage({ id: '热点资讯' })} </title> 
            </Head> */}
            <Header muneActivepr={3}></Header>
            <div className='flex flex-col items-center justify-center w-[1920px]'>
                <img src={locale == 'zh' ? '/images/hotzh.jpg' : (locale == 'jp' ? '/images/hotjp.jpg' : '/images/hoten.jpg')} width="1920" height='520' alt=""></img>

                <div className='w-[1300px]'>
                    {
                        type.map((el, index) => {
                            return (
                                index == 0 ? (
                                    <div key={index}>
                                        <div className='flex justify-between items-center mt-[80px] w-[1300px]' >
                                            <div className='flex items-center'>
                                                <div className="w-1 h-8 bg-blue-800"></div>
                                                <div className="text-[#2557A7]  text-[28px] font-medium   ml-[14px] leading-[100%]">{el.title}</div>
                                            </div>
                                            <div onClick={() => gopage('/hotNews/officialConsultation', { state: { id: el.id, title: el.title } })} locale={locale} a={1}>
                                                <div className='flex  items-center text-[16px] text-[#999] cursor-pointer'>
                                                    {intl.formatMessage({ id: '更多' })}
                                                    <div className="px-[7px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-[44px] flex justify-between'>
                                            <div className='w-[684px] h-[430px] relative ' onMouseEnter={() => setMous(true)} onMouseLeave={() => setMous(false)}>
                                                <Carousel autoplay style={{ height: '430px', width: '684px' }} dots={false} ref={carousel} >
                                                    {
                                                        swiperList.map(el => {
                                                            return (
                                                                <img src={el.image || ''} key={el.id} width="684" height='430' alt="" className=' h-[430px] w-[684px] object-cover'></img>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                                {
                                                    onmous ? ( 
                                                        <> 
                                                            <div onClick={button_left} className="w-[54px] h-[430px] left-0 top-0 cursor-pointer absolute  bg-black bg-opacity-20 flex justify-center items-center text-[#fff] text-[22px]" style={{ fontFamily: '宋体' }}>&lt;</div>
                                                            <div onClick={button_right} className="w-[54px] h-[430px] right-0 top-0 absolute  cursor-pointer bg-black bg-opacity-20 flex justify-center items-center text-[#fff] text-[22px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                                                        </>
                                                    ) : ''
                                                }
                                            </div>
                                            <div className='pl-[27px] flex-1 box-border'>
                                                {
                                                    el.data.map((item, index) => {
                                                        return (
                                                            <div onClick={() => gopage('/hotNews/hotNewsDetails?id='+item.id, { state: { id: item.id } })} key={index}>
                                                                <div className={`box cursor-pointer flex h-[133px] ${index != 2 ? " mb-[16px]" : ''}`} >
                                                                    <img src={item.image || ''} width="212" height='133' alt="" className='w-[212px] h-[133px] object-cover'></img>
                                                                    <div className='ml-[10px] flex justify-between flex-col h-[133px] pt-[7px] pb-[16px] box-border '>
                                                                        <style jsx>
                                                                            {
                                                                                `.box:hover{
                                                                                    .title{
                                                                                        color:#2557A7
                                                                                    }
                                                                                }`
                                                                            }
                                                                        </style>
                                                                        <div className="title w-[367px] text-[#333] text-[18px] font-medium  leading-[27px] overflow-hidden text-ellipsis whitespace-nowrap">{item.title}</div>
                                                                        <div className="w-[367px] text3  text-neutral-400 text-base font-normal leading-normal">{item.intro}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    el.data.length>0?
                                    <div key={index} >
                                        <div className='flex justify-between items-center mt-[80px] w-[1300px]'>
                                            <div className='flex items-center'>
                                                <div className="w-1 h-8 bg-[#2557A7]"></div>
                                                <div className="text-[#2557A7]  text-[28px] font-medium  leading-[100%] ml-[14px]">{el.title}</div>
                                            </div>
                                            <div onClick={() => gopage('/hotNews/officialConsultation', { state: { id: el.id, title: el.title } })} locale={locale} a={1}>
                                                <div className='flex  items-center text-[16px] text-[#999] cursor-pointer'>
                                                    {intl.formatMessage({ id: '更多' })}
                                                    <div className="px-[7px] " style={{ fontFamily: '宋体' }}>&gt;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-[44px] flex'>
                                            {
                                                el.data.map((item, index1) => {
                                                    return (
                                                        <div onClick={() => gopage('/hotNews/hotNewsDetails?id='+item.id, { state: { id: item.id } })} key={index1}>
                                                            <div className='mr-[28px]  cursor-pointer box'>
                                                                <style jsx>
                                                                    { 
                                                                        `.box:hover{
                                                                                    .title{
                                                                                        color:#2557A7
                                                                                    }
                                                                                }`
                                                                    }
                                                                </style>
                                                                <img src={item.image || ''} width="415" height='480' alt="" className='w-[415px] h-[480px] object-cover rounded-tl-lg rounded-tr-lg'></img>
                                                                <div className="title w-[415px] h-[116px]  bg-neutral-50  py-[28px] rounded-bl-lg rounded-br-lg  flex px-[25px] box-border text-[#333] text-xl font-medium  leading-[30px] textsis2">
                                                                    <span className='text2'>{item.title}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> : ''
                                )

                            )
                        })
                    }
                </div>
                <div className="w-[1300px]  mt-[69px] mb-[80px] flex items-center  text-[#2557A7] text-[16px] font-normal  leading-[100%]">
                    <div className="text-[#333]  font-normal ] ">{intl.formatMessage({ id: '首页' })} </div>
                    <div className="px-[17px]" style={{ fontFamily: '宋体' }}>&gt;</div>
                    <div className="">{intl.formatMessage({ id: '热点资讯' })}</div>
                </div>
                <Footer />
            </div>
        </div>
    )
}