// locales/index.ts
import zh from './zh';
import en from './en';
import jp from './jp';

// import ls from 'src/utils/localStore';
import { createIntl, createIntlCache } from 'react-intl';

// const _currentLang = ls.getItem('qs_lang') || 'zh-CN';
const _currentLang =localStorage.getItem('lang') ||  'zh'
const messages = {
  'zh': zh,
  'en': en,
  'jp': jp,
};
export const getCurrentLang = () => _currentLang;
export const getCurrentMessages = () => messages[_currentLang];

const cache = createIntlCache(); 
const intl = createIntl( 
  { 
    locale: _currentLang,  
    messages: getCurrentMessages(),
  },
  cache
);

export default intl;
